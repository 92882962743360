import { APP_INITIALIZER, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { FormlyModule } from '@ngx-formly/core';
import { TypeOption } from '@ngx-formly/core/lib/models';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { RcgFieldType } from '@rcg/core';
import { FormlyFormComponent } from './containers/formly-form/formly-form.component';
import { FieldActionsWrapperComponent } from './form-layouts/field-actions-wrapper.component';
import { FormContainerWrapperComponent } from './form-layouts/form-container-wrapper/form-container-wrapper.component';
import { LazyLoadFieldComponent, registerLazyLoadField } from './lazy-load-field.component';
import { OpenFormActionRegistrationService } from './open-form-templater-action';
import {
  RcgValidationMessages,
  currencyValidatorMessage,
  emailValidatorMessage,
  maxAllowedAttachmentsValidatorMessage,
  maxLengthValidationMessage,
  minLengthValidationMessage,
} from './validators/validation.messages';
import {
  contactFirstNameLastNameValidator as contactNameValidator,
  currencyValidator,
  emailValidator,
  emailValidatorAllowEmpty,
  invalidAcObjectValidator,
  maxAllowedAttachmentsValidator,
  minTimeSeconds,
  requiredFieldValidator,
  workingHoursDateRangeValidator,
} from './validators/validators';
import { MultiLanguageFieldWrapperComponent } from './wrappers/multi-language/multi-language.component';

export type LazyLoadTypeOption = Omit<TypeOption, 'component'> & { loadComponent: () => Promise<Type<RcgFieldType<unknown, object>>> };

@NgModule({
  imports: [
    FormlyMaterialModule,
    FormlyMatDatepickerModule,
    FormlyModule.forChild(),
    FormlyFormComponent,
    MultiLanguageFieldWrapperComponent,
    FieldActionsWrapperComponent,
    FormContainerWrapperComponent,
  ],
  exports: [FormlyModule, FormlyFormComponent],
  providers: [
    OpenFormActionRegistrationService,
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [OpenFormActionRegistrationService],
      multi: true,
    },
  ],
})
export class RcgFormsModule {
  public static lazyLoadField({ loadComponent, ...options }: LazyLoadTypeOption): TypeOption {
    registerLazyLoadField(options.name, loadComponent);

    return {
      ...options,
      component: LazyLoadFieldComponent,
    };
  }

  public static lazyLoadFields(options: LazyLoadTypeOption[]): TypeOption[] {
    return options.map((o) => this.lazyLoadField(o));
  }

  public static forFormlyRoot(options?: { extraTypes?: TypeOption[] }): ModuleWithProviders<FormlyModule> {
    return FormlyModule.forRoot({
      extras: {
        lazyRender: true,
        immutable: true, // must be enabled (modelChange) on formly-form component if imutable true
        resetFieldOnHide: false, // if this is true (default) fields with a default model value will lose their value when hidden
      },
      wrappers: [
        { name: 'multi-language', component: MultiLanguageFieldWrapperComponent },
        { name: 'actions', component: FieldActionsWrapperComponent },
        { name: 'form-container', component: FormContainerWrapperComponent },
      ],
      types: [
        ...this.lazyLoadFields([
          {
            name: 'fields',
            loadComponent: () => import('./form-layouts/fields-wrapper/fields-wrapper.component').then((m) => m.RcgFieldsWrapperComponent),
          },
          {
            name: 'singleForm',
            loadComponent: () => import('./form-layouts/single-form/single-form.component').then((m) => m.RcgSingleFormComponent),
          },
          {
            name: 'tabs',
            loadComponent: () => import('./form-layouts/form-tabs/form-tabs.component').then((m) => m.RcgFormTabsComponent),
          },
          {
            name: 'templaterTemplate',
            loadComponent: () =>
              import('./fields/templater-template/templater-template.component').then((m) => m.TemplaterTemplateComponent),
          },
          {
            name: 'stepper',
            loadComponent: () => import('./form-layouts/stepper/stepper.component').then((m) => m.RcgFormStepperComponent),
          },
          {
            name: 'heading',
            loadComponent: () => import('./fields/heading-field/heading-field.component').then((m) => m.HeadingFieldComponent),
          },
          {
            name: 'timespent',
            loadComponent: () => import('./fields/time-spent-field/time-spent-field.component').then((m) => m.TimeSpentFieldComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'date-picker',
            loadComponent: () => import('./fields/date-picker/date-picker.component').then((m) => m.DatePickerFieldComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'datetimepicker',
            loadComponent: () =>
              import('./fields/datetime-picker-field/datetime-picker-field.component').then((m) => m.DatetimePickerFieldComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'status',
            loadComponent: () => import('./fields/status-field/status-field.component').then((m) => m.StatusFieldComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'attachment',
            loadComponent: () => import('./fields/attachments-field/attachments-field.component').then((m) => m.AttachmentsFieldComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'autocomplete',
            loadComponent: () =>
              import('./fields/autocomplete/autocomplete-field/autocomplete-field.component').then((m) => m.AutocompleteFieldComponent),
            wrappers: ['form-field'],
            defaultOptions: { validators: { validation: ['invalidAcObject'] } },
          },
          {
            name: 'autocompletemultiselect',
            loadComponent: () =>
              import('./fields/autocomplete/autocomplete-multiselect-field/autocomplete-multiselect-field.component').then(
                (m) => m.AutocompleteMultiselectFieldComponent,
              ),
            wrappers: ['form-field'],
            defaultOptions: { validators: { validation: ['invalidAcObject'] } },
          },
          {
            name: 'autocompleteTree',
            loadComponent: () =>
              import('./fields/autocomplete/autocomplete-tree-field/autocomplete-tree-field.component').then(
                (m) => m.AutocompleteTreeFieldComponent,
              ),
            wrappers: ['form-field'],
          },
          {
            name: 'chips',
            loadComponent: () => import('./fields/chips/chips.component').then((m) => m.ChipsComponent),
          },
          {
            name: 'plainText',
            loadComponent: () => import('./fields/text-area-field/text-area-field.component').then((m) => m.TextAreaFieldComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'htmleditor',
            loadComponent: () => import('./fields/html-editor-field/html-editor-field.component').then((m) => m.HtmlEditorFieldComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'signature',
            loadComponent: () => import('./fields/signature-field/signature-field.component').then((m) => m.SignatureFieldComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'hint',
            loadComponent: () => import('./fields/autocomplete/hint-field/hint-field.component').then((m) => m.HintFieldComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'rating',
            loadComponent: () => import('./fields/rating-field/rating-field.component').then((m) => m.RatingFieldComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'location',
            loadComponent: () => import('./fields/location/location.component').then((m) => m.LocationComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'phone',
            loadComponent: () => import('./fields/phone/phone.component').then((m) => m.PhoneComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'dateTimeInputDouble',
            loadComponent: () =>
              import('./fields/datetime-input-double/datetime-input-double.component').then((m) => m.DateTimeInputDoubleComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'timeInputDouble',
            loadComponent: () => import('./fields/time-input-double/time-input-double.component').then((m) => m.TimeInputDoubleComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'rcgTime',
            loadComponent: () => import('./fields/time-field/time-field.component').then((m) => m.TimeFieldComponent),
          },
          {
            name: 'passwordChangeInput',
            loadComponent: () =>
              import('./fields/password-change-input/password-change-input.component').then((m) => m.PasswordChangeInputComponent),
          },
          {
            name: 'mfaConfigurator',
            loadComponent: () => import('./fields/mfa-configurator/mfa-configurator.component').then((m) => m.MFAConfiguratorComponent),
          },
          {
            name: 'sort',
            loadComponent: () => import('./fields/sort-field/sort-field.component').then((m) => m.SortFieldComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'recurrenceeditor',
            loadComponent: () =>
              import('./fields/recurrence-editor/recurrence-editor.component').then((m) => m.RecurrenceEditorFieldComponent),
          },
          {
            name: 'groupEditor',
            loadComponent: () => import('./fields/group-editor/group-editor.component').then((m) => m.GroupEditorFieldComponent),
          },
          {
            name: 'iconPicker',
            loadComponent: () => import('./fields/icon-picker-field/icon-picker-field.component').then((m) => m.IconPickerFieldComponent),
          },
          {
            name: 'rcgSelect',
            loadComponent: () => import('./fields/select/select.component').then((m) => m.RcgSelectFieldComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'multiselect',
            loadComponent: () => import('./fields/multiselect/multiselect-field.component').then((m) => m.MultiselectFieldComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'currency',
            loadComponent: () => import('./fields/currency/currency-field.component').then((m) => m.CurrencyFieldComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'input-hint',
            loadComponent: () => import('./fields/input-hint/input-hint-field.component').then((m) => m.InputHintFiledComponent),
            wrappers: ['form-field'],
          },
          {
            name: 'rcg-list',
            loadComponent: () => import('./fields/list/list-field.component').then((m) => m.ListFieldComponent),
          },
          {
            name: 'taxNumber',
            loadComponent: () => import('./fields/tax-number/tax-number-field.component').then((m) => m.TaxNumberFieldComponent),
            wrappers: ['form-field'],
          },
        ]),

        ...(options?.extraTypes ?? []),
      ],
      validators: [
        { name: 'invalidAcObject', validation: invalidAcObjectValidator },
        { name: 'requiredField', validation: requiredFieldValidator },
        { name: 'email', validation: emailValidator },
        { name: 'emailAllowEmpty', validation: emailValidatorAllowEmpty },
        {
          name: 'workingHoursDateRange',
          validation: workingHoursDateRangeValidator,
        },
        { name: 'min-time', validation: minTimeSeconds },
        { name: 'contact-name', validation: contactNameValidator },
        { name: 'currency', validation: currencyValidator },
        { name: 'maxAllowedAttachments', validation: maxAllowedAttachmentsValidator },
      ],
      validationMessages: [
        {
          name: 'invalidAcObject',
          message: RcgValidationMessages.invalidAcObject,
        },
        { name: 'requiredField', message: RcgValidationMessages.requiredField },
        { name: 'minLength', message: minLengthValidationMessage },
        { name: 'maxLength', message: maxLengthValidationMessage },
        { name: 'email', message: emailValidatorMessage },
        { name: 'emailAllowEmpty', message: emailValidatorMessage },
        { name: 'currency', message: currencyValidatorMessage },
        { name: 'maxAllowedAttachments', message: maxAllowedAttachmentsValidatorMessage },
      ],
      extensions: [],
    });
  }
}
